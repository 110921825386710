export const navBarData = [
  {
    id: 1,
    title: "Accueil",
    link: "/",
    set: "",
    class: "navbar-container",
    type: "Nav",
    order: 1,
  },

  {
    id: 2,
    title: "Nos Prestations",
    link: "/prestations",
    set: "",
    class: "navbar-container",
    type: "Nav",
    order: 1,
    submenu: "responsive-button",
  },
  {
    id: 3,
    title: "Contact",
    link: "/contact",
    set: "",
    class: "navbar-container",
    type: "Nav",
    order: 1,
  },
];

export const secondNavbar = [
  {
    id: 1,
    title: "Nettoyage toiture et façade",
    link: "/prestations/nettoyage",
    set: "false",
    dataClass: "nettoyage-img",
    type: "middleNav",
    order: "m1",
    image: `11`,
  },
  {
    id: 2,
    title: "Peinture",
    link: "/prestations/peinture",
    set: "false",
    dataClass: "peinture-img",
    type: "middleNav",
    order: "m2",
    image: "12",
  },
  {
    id: 3,
    title: "Ramonage",
    link: "/prestations/ramonage",
    set: "false",
    dataClass: "ramonage-img",
    type: "middleNav",
    order: "m3",
    image: "13",
  },
  {
    id: 4,
    title: "Couverture",
    link: "/prestations/couverture",
    set: "false",
    dataClass: "couverture-img",
    type: "middleNav",
    order: "m4",
    image: "14",
  },
  {
    id: 5,
    title: "Diverse Travaux",
    link: "/prestations/diverse",
    set: "false",
    dataClass: "service-image",
    type: "middleNav",
    order: "m4",
    image: "14",
  },
];
