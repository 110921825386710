import React from "react";
import NavBar from "./Navbar.jsx";
import Logo from "./Logo.jsx";

const Header = () => {
  return (
    <header>
      <Logo />
      <NavBar />
    </header>
  );
};

export default Header;
