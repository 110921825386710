export const servicesData = [
  {
    id: 1,
    title: "Nos Prestations",
    details: `Bienvenue sur notre page dédiée au nettoyage, à la peinture et à l'entretien de vos façades, 
    toitures, charpentes et bien plus encore ! 
    Nous sommes une équipe de professionnels expérimentés qui se spécialise dans tous les aspects liés à
    l'embellissement et à la préservation de votre espace extérieur.`,
    details1: `Faites confiance à notre équipe compétente et passionnée pour tous vos besoins en matière de nettoyage,
    de peinture, de traitement anti-mousse et d'entretien de charpentes. Contactez-nous dès maintenant pour discuter de 
    vos projets et obtenir un devis personnalisé.
    Nous sommes impatients de travailler avec vous pour rendre votre espace extérieur propre, beau et durable`,
    details2: "",
  },
  {
    id: 2,
    title: "Façade",
    details: `Lorsqu'il s'agit de nettoyer votre façade, nous utilisons des techniques avancées et des produits de qualité pour 
    éliminer efficacement les salissures, les taches et les résidus. Que ce soit pour enlever les traces de pollution, les dépôts de 
    saleté ou les marques d'usure, nous redonnerons à votre façade son éclat d'origine.`,
    title1: "Toitures",
    details1: `Votre toiture est exposée aux éléments tout au long de l'année, ce qui peut entraîner l'accumulation de mousse, de 
    lichen et d'autres végétaux nuisibles. Notre équipe compétente effectue un nettoyage approfondi de votre toiture pour éliminer
    ces nuisances et prévenir les problèmes d'étanchéité. Nous veillons à utiliser des méthodes respectueuses de l'environnement
    et des produits adaptés pour préserver la durabilité de votre toiture.`,
    details2: "",
  },
  {
    id: 3,
    title: "Peinture",
    details: `Bienvenue sur notre page dédiée au nettoyage, à la peinture et à l'entretien de vos façades, 
    toitures, charpentes et bien plus encore ! 
    Nous sommes une équipe de professionnels expérimentés qui se spécialise dans tous les aspects liés à
    l'embellissement et à la préservation de votre espace extérieur.`,
    details1: `Faites confiance à notre équipe compétente et passionnée pour tous vos besoins en matière de nettoyage,
    de peinture, de traitement anti-mousse et d'entretien de charpentes. Contactez-nous dès maintenant pour discuter de 
    vos projets et obtenir un devis personnalisé. Nous sommes impatients de travailler avec vous pour rendre votre espace 
    extérieur propre, beau et durable`,
    details2: "",
  },
  {
    id: 4,
    title: "Nos Services",
    details: `Bienvenue sur notre page dédiée au nettoyage, à la peinture et à l'entretien de vos façades, 
    toitures, charpentes et bien plus encore ! 
    Nous sommes une équipe de professionnels expérimentés qui se spécialise dans tous les aspects liés à
    l'embellissement et à la préservation de votre espace extérieur.`,
    details1: `Faites confiance à notre équipe compétente et passionnée pour tous vos besoins en matière de nettoyage,
    de peinture, de traitement anti-mousse et d'entretien de charpentes. Contactez-nous dès maintenant pour discuter de 
    vos projets et obtenir un devis personnalisé.
    Nous sommes impatients de travailler avec vous pour rendre votre espace extérieur propre, beau et durable`,
    details2: "",
  },
];

export const servicesIntro = {
  servicesTitle: "Nos Prestations",
  services: `Bienvenue dans notre rubrique dédiée à nos services complets pour répondre à tous vos besoins en matière 
  d'entretien, de rénovation et de travaux divers. Chez Entreprise Ziegler, nous sommes fiers de vous offrir une gamme 
  étendue de services professionnels pour embellir et maintenir votre espace extérieur.`,
};
