import React from "react";
import { peintureData } from "../data/servicesData.js";

const Peinture = () => {
  return (
    <div className="nettoyage-container">
      <div className="nettoyage">
        <div className="nettoyage-top">
          {peintureData
            .filter((filterId) => filterId.id === 1)
            .map((getData, index) => (
              <div key={index} className="nettoyage-top-left">
                <h1>{getData.title}</h1>
                <h3 className="one">{getData.textData}</h3>
                <p>{getData.textData1}</p>
                <p>{getData.textData2}</p>
                <p>{getData.textData3}</p>
                <p>{getData.textData4}</p>
                <p>{getData.textData5}</p>
              </div>
            ))}
        </div>
        <div className="nettoyage-bottom-left">
          {peintureData
            .filter((filterId) => filterId.id === 2)
            .map((getData) =>
              getData.toitureDataImage.map((getImg) => (
                <figure className={getImg.order} key={getImg.id}>
                  <img src={getImg.image} alt={getImg.title} />
                </figure>
              ))
            )}
        </div>
      </div>
    </div>
  );
};

export default Peinture;
