export const config = {
  // api: `${process.env.REACT_APP_API}`,
  title: "Contactez-nous",
  title1: "Nous joindre",
  title2: "Nous joindre",
  title3: "Nous joindre",
  description:
    "Besoin d'aide ou de renseignements ? Contactez-nous via le formulaire et nous vous répondrons dès que possible. Merci !",
  successMessage: "Je vous remercie d'avoir pris contact avec moi !",
  //  errorMessage: "Please fill the complete form",
  details: `Nous sommes ravis de vous accueillir sur notre page de contact ! Si vous avez des questions, des commentaires ou des demandes spécifiques, 
    n'hésitez pas à nous contacter en remplissant le formulaire. Nous attachons une grande importance à vos retours et nous nous efforçons de 
    vous fournir une réponse rapide et précise. Votre opinion compte pour nous, et nous sommes là pour vous aider. Merci de prendre le temps de
    nous contacter, nous attendons avec impatience de vous entendre.`,
  thanks: `   Cordialement,
    L'équipe de l'Entreprise Ziegler`,
  phone: `06.67.55.28.77`,
  address: `107 Rue de la Joigne, 50750, Canisy`,
  zone: `Nous couvrons : Saint-Lô, Coutances, Cherbourg, Caen,\n Flers dans l'Orne et dans un rayon de 10o km aux alentours Canisy.`,
  horaire: `Contact par téléphone : du lundi au samedi de 9h à 12h et de 13h30 à 19h`,
  horaireTravail: `Intervention : du lundi au samedi de 9h à 12h et de 13h30 à 19h`,
  email: `ginorenove@gmail.com`,
  social: ``,
  facebook: ``,
  linkedin: ``,
  instagram: ``,
  youtube: ``,
  whatsup: ``,
  tweeter: ``,
  fields: {
    /* firstName: "",
    lastName: "",*/
    email: "",
    subject: "",
    message: "",
  },
  fieldsConfig: [
    /*{
      id: 1,
      label: "First Name",
      fieldName: "firstName",
      type: "text",
      placeholder: "Prenom",
      isRequired: true,
      classType: "first-name",
    },
    {
      id: 2,
      label: "Last Name",
      fieldName: "lastName",
      type: "text",
      placeholder: "Nom",
      isRequired: true,
      classType: "last-name",
    },*/
    {
      id: 3,
      label: "Email",
      fieldName: "email",
      type: "email",
      placeholder: "Courriel",
      isRequired: true,
      classType: "email",
      error: "Courriel manquant !",
    },
    {
      id: 4,
      label: "Subject",
      fieldName: "subject",
      type: "text",
      placeholder: "Sujet",
      isRequired: true,
      classType: "subject",
      error: "Sujet manquant !",
    },
    {
      id: 5,
      label: "Message",
      fieldName: "message",
      type: "textarea",
      placeholder: "Ecrire une message...",
      isRequired: true,
      classType: "message",
      error: "Message manquant !",
    },
  ],
};
