import React from "react";
import { Routes, Route } from "react-router-dom";
import Error404 from "../pages/Errorpage.jsx";
import Home from "../pages/Main.jsx";
import Services from "../pages/Services.jsx";
import Contact from "../pages/Contact.jsx";
import Nettoyage from "../pages/Nettoyage.jsx";
import Peinture from "../pages/Peinture.jsx";
import Ramonage from "../pages/Ramonage.jsx";
import Couverture from "../pages/Couverture.jsx";
import Diverse from "../pages/Diverse.jsx";

const MainRoutes = () => {
  //  const { tenerife, asia, europe } = AuthUser();
  return (
    <main>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/">
          <Route path="prestations" element={<Services />} />
          <Route path="prestations/nettoyage" element={<Nettoyage />} />
          <Route path="prestations/peinture" element={<Peinture />} />
          <Route path="prestations/couverture" element={<Couverture />} />
          <Route path="prestations/ramonage" element={<Ramonage />} />
          <Route path="prestations/diverse" element={<Diverse />} />
        </Route>
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </main>
  );
};
export default MainRoutes;
