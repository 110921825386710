import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AuthUser } from "../context/Auth.jsx";
import ResponsiveNavBar from "../components/ResponsiveNavbar.jsx";

const NavBar = ({ ...props }) => {
  const { navBarData } = AuthUser();
  const [responsiveActive, setResponsiveActive] = useState(false);

  useEffect(() => {}, [responsiveActive]);

  return (
    <div
      className="navbar-wrapper"
      onMouseOut={() => {
        setResponsiveActive(false);
      }}
    >
      <div className="navbar-container">
        <nav>
          {navBarData.map((nav, index) => {
            return (
              <ul key={nav.id}>
                <li
                  className={"responsive-active"}
                  onMouseOver={() => {
                    nav.id === 2 && setResponsiveActive(true);
                  }}
                >
                  <NavLink to={nav.link}>
                    {nav.title}
                    {nav.id === 2 &&
                      (responsiveActive ? (
                        <span className="material-icons off">
                          arrow_drop_down
                        </span>
                      ) : (
                        <span className="material-icons open">
                          arrow_drop_down
                        </span>
                      ))}
                  </NavLink>
                </li>
                <li className={"responsive-hidden"} key={nav.id + index}>
                  <NavLink to={nav.link}>{nav.title}</NavLink>
                </li>
              </ul>
            );
          })}
        </nav>
      </div>
      <div
        className={
          ("responsive-menu",
          responsiveActive ? "responsive-menu on" : "responsive-menu")
        }
      >
        <ResponsiveNavBar
          responsiveActive={responsiveActive}
          setResponsiveActive={setResponsiveActive}
        />
      </div>
    </div>
  );
};

export default NavBar;
