import { createContext, useContext } from "react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { navBarData } from "../data/navData.js";
import { secondNavbar } from "../data/navData.js";

/* Create Authority Context */
export const AuthUserContext = createContext();
export const AuthUser = () => {
  return useContext(AuthUserContext);
};

export const AuthContextProvider = ({ children }) => {
  /* Set Context Variables */
  let [off, setOff] = useState(false);
  const location = useLocation();
  const loc = location.pathname === "/prestations";

  /* HANDLE DATES *
  const date = new Date();

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const currentDate = `Date : ${date.toLocaleString("fr-FR", options)}`;
*/

  if (loc === true) {
    off = true;
  } else {
    off = false;
  }

  const authValues = {
    off,
    setOff,
    secondNavbar,
    navBarData,
  };

  return (
    <AuthUserContext.Provider value={authValues}>
      {children}
    </AuthUserContext.Provider>
  );
};
