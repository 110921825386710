/* toiture */
/*1*/
import img1 from "../assets/avant03.webp";
import img1b from "../assets/apres03.webp";
/*2*/
import img2 from "../assets/avant02.webp";
import img2b from "../assets/apres02.webp";
/*facade*/
import img3 from "../assets/avant01.webp";
import img3b from "../assets/apres01.webp";
/*3*/
import img4 from "../assets/avant05.webp";
import img4b from "../assets/apres05.webp";
/*4*/
import img5 from "../assets/avant06.webp";
import img5b from "../assets/apres06.webp";
/*5*/
import img6 from "../assets/avant07.webp";
import img6b from "../assets/apres07.webp";
/*6*/
import img1c from "../assets/avant-apres01.webp";
import img23 from "../assets/apres16.webp";

/*facade peinture */
/*7*/
import img10 from "../assets/avant10.webp";
import img10b from "../assets/apres10.webp";
/*8*/
import img11 from "../assets/apres11.webp";
/*9*/
import img7 from "../assets/avant08.webp";
import img7b from "../assets/apres08.webp";
/*10*/
import img8 from "../assets/avant09.webp";
import img8b from "../assets/apres09.webp";
/*12*/
import img21 from "../assets/avant17.webp";
import img21b from "../assets/apres17.webp";
/* faîtières */
/*13*/
import img12 from "../assets/avant12.webp";
import img12b from "../assets/apres12.webp";
import img13 from "../assets/avant13.webp";
import img14 from "../assets/avant14.webp";
/* diverse*/
/*14*/
import img15 from "../assets/diverse01avant.webp";
import img15b from "../assets/diverse01apres.webp";
/*15*/
import img16 from "../assets/diverse02avant.webp";
import img16b from "../assets/diverse02apres.webp";
/*16*/
import img17 from "../assets/diverse03avant.webp";
import img17b from "../assets/diverse03apres.webp";
/*17*/
import img18 from "../assets/diverse04avant.webp";
import img18b from "../assets/diverse04apres.webp";
/*18*/
import img19 from "../assets/diverse05avant.webp";
import img19b from "../assets/diverse05apres.webp";
/*19*/
import img20 from "../assets/diverse06avant.webp";
import img20b from "../assets/diverse06apres.webp";
/*20*/
import img22 from "../assets/diverse07.webp";
import img24 from "../assets/diverse08.webp";

export const toitureData = [
  {
    id: 1,
    title: "Traitement ou nettoyage de toiture",
    textData: `Nous proposons un service complet de nettoyage de toiture, y compris le démoussage manuel, pour vous aider à maintenir votre toit en bon état. Nous comprenons l'importance d'un toit propre et dépourvu de mousses, de lichens et d'autres dépôts indésirables, tant sur le plan esthétique que sur celui de la durabilité de votre toiture.`,
    subTitle1: ``,
    textData1: `Lorsque nous effectuons le nettoyage de votre toiture, nous commençons par enlever les débris tels que les feuilles, les brindilles et autres détritus qui peuvent s'accumuler au fil du temps. Ensuite, nous procédons au démoussage manuel de votre toit. Nos professionnels utilisent des outils spécifiques pour enlever délicatement les mousses, les lichens et autres végétations indésirables qui se sont développés sur votre toiture. Cette méthode manuelle permet d'obtenir des résultats plus précis et de préserver l'intégrité de votre toit.`,
    subTitle2: ``,
    textData2: `Le démoussage manuel est particulièrement efficace pour éliminer les mousses incrustées et les lichens tenaces qui peuvent endommager votre toiture à long terme. Il permet également d'inspecter votre toit de près, ce qui nous permet de repérer d'éventuels problèmes ou dommages et de les signaler.`,
    subTitle3: ``,
    textData3: `Après avoir effectué le nettoyage et le démoussage manuel de votre toiture, nous appliquons un traitement spécifique qui aide à prévenir la formation de nouvelles mousses et lichens. Ce traitement agit en profondeur pour inhiber la croissance des végétations indésirables, tout en préservant l'intégrité de votre toiture.`,
    subTitle4: ``,
    textData4: `Nos produits de traitement sont sélectionnés avec soin pour leur efficacité et leur respect de l'environnement. Ils sont appliqués de manière professionnelle, en veillant à couvrir uniformément toute la surface de votre toiture. Le traitement laisse une couche protectrice invisible qui empêche les mousses et les lichens de se développer à nouveau.`,
    subTitle5: ``,
    textData5: `Le traitement de votre toiture prolonge la durabilité de votre couverture en réduisant les risques d'humidité et de détérioration liés à la présence de mousses et de lichens. Il contribue également à maintenir l'aspect esthétique de votre toit en évitant les taches et les décolorations causées par ces végétations.`,
    subTitle6: ``,
    textData6: `Pour vous donner un aperçu des résultats que nous obtenons, nous mettons à votre disposition une galerie de photos avant/après. Ces images témoignent de l'état initial de votre toiture, souvent recouverte de mousses et de lichens, puis du résultat final après notre intervention professionnelle de nettoyage et de démoussage manuel. Vous pourrez constater par vous-même la différence remarquable que cela fait pour l'apparence et la durabilité de votre toit.`,
    subTitle7: ``,
    textData7: `Nous sommes fiers de notre expertise en matière de nettoyage de toiture et de démoussage manuel, et nous nous engageons à fournir des résultats exceptionnels à nos clients. La galerie de photos avant/après est une preuve tangible de notre dévouement envers la qualité et la satisfaction de nos clients. Contactez-nous dès aujourd'hui pour discuter de vos besoins en matière de nettoyage de toiture et de démoussage manuel, et découvrez comment nous pouvons maintenir votre toit en excellent état.`,
  },
  {
    id: 2,
    toitureDataImage: [
      {
        id: 1,
        order: "i01",
        image: img1,
        title: "1",
      },
      {
        id: 2,
        order: "i02",
        image: img1b,
        title: "2",
      },
      {
        id: 3,
        order: "i03",
        image: img2,
        title: "3",
      },
      {
        id: 4,
        order: "i04",
        image: img2b,
        title: "4",
      },
      {
        id: 5,
        order: "i05",
        image: img1c,
        title: "5 ",
      },
      {
        id: 6,
        order: "i06",
        image: img23,
        title: "6",
      },
    ],
  },
];
export const facadeData = [
  {
    id: 1,
    title: "Nettoyage et traitement façade",
    textData: `Nous sommes spécialisés dans le nettoyage et le traitement de façades, et nous sommes fiers de vous présenter notre service avec une galerie avant/après pour illustrer les résultats spectaculaires que nous obtenons.`,
    subTitle1: ``,
    textData1: `La façade de votre bâtiment est soumise aux intempéries, à la pollution, à la saleté et à d'autres facteurs qui peuvent lui faire perdre son éclat et son apparence initiale. C'est là que notre équipe d'experts entre en jeu. Nous utilisons des techniques de nettoyage professionnelles, des produits de qualité et des équipements spécialisés pour redonner à votre façade toute sa splendeur.`,
    subTitle2: ``,
    textData2: `Avant de commencer les travaux, nous réalisons une évaluation complète de l'état de votre façade. Cela nous permet de déterminer les meilleures méthodes de nettoyage et de traitement à utiliser pour obtenir des résultats optimaux. Que votre façade soit en brique, en pierre, en béton ou en d'autres matériaux, nous adaptons nos techniques pour garantir un nettoyage en profondeur sans endommager la surface.`,
    subTitle3: ``,
    textData3: `Une fois le nettoyage terminé, nous procédons au traitement de la façade pour la protéger contre les effets néfastes des intempéries, de la pollution et des moisissures. Nous utilisons des produits de qualité supérieure qui agissent comme une barrière de protection, préservant l'intégrité de votre façade et minimisant les risques de détérioration future.`,
    subTitle4: ``,
    textData4: `Pour vous donner un aperçu de la transformation remarquable que nous pouvons réaliser, nous mettons à votre disposition une galerie avant/après. Ces images témoignent de l'état initial de la façade, souvent sale, décolorée et terne, puis du résultat final après notre intervention professionnelle. Vous pourrez constater par vous-même l'efficacité de nos services et l'impact significatif que nous apportons à l'apparence et à la valeur de votre bâtiment.`,
    subTitle5: ``,
    textData5: `Nous sommes passionnés par notre travail et nous nous engageons à fournir des résultats exceptionnels à nos clients. Notre galerie avant/après est une preuve concrète de notre engagement envers la qualité et la satisfaction de nos clients. Faites confiance à notre expertise pour redonner à votre façade tout son éclat et sa beauté d'origine. Contactez-nous dès aujourd'hui pour en savoir plus sur nos services de nettoyage et de traitement de façades.`,
  },
  {
    id: 2,
    toitureDataImage: [
      {
        id: 1,
        order: "i01",
        image: img3,
        title: "1",
      },
      {
        id: 2,
        order: "i02",
        image: img3b,
        title: "2",
      },
      {
        id: 3,
        order: "i03",
        image: img4,
        title: "3",
      },
      {
        id: 4,
        order: "i04",
        image: img4b,
        title: "4",
      },
      {
        id: 5,
        order: "i05",
        image: img5,
        title: "5",
      },
      {
        id: 6,
        order: "i06",
        image: img5b,
        title: "6",
      },
      {
        id: 7,
        order: "i07",
        image: img8,
        title: "7",
      },
      {
        id: 8,
        order: "i08",
        image: img8b,
        title: "8",
      },
      {
        id: 9,
        order: "i09",
        image: img6,
        title: "9",
      },
      {
        id: 10,
        order: "i10",
        image: img6b,
        title: "10",
      },
    ],
  },
];

export const peintureData = [
  {
    id: 1,
    title: "Peinture façade et toiture",
    textData: `Nous sommes spécialisés dans la peinture de façades et nous sommes heureux de présenter notre service détaillé, accompagné d'une galerie de photos avant/après pour illustrer les résultats impressionnants que nous obtenons.`,
    subTitle1: ``,
    textData1: `Lorsque votre façade montre des signes de détérioration, de décoloration ou de vieillissement, la peinture peut lui redonner une apparence fraîche et attrayante. Notre équipe d'experts en peinture de façades est compétente dans toutes les étapes du processus, de la préparation à l'application de la peinture, en passant par les éventuelles réparations des surfaces endommagées.`,
    subTitle2: ``,
    textData2: `Avant de commencer le projet, nous évaluons l'état de votre façade et discutons de vos préférences en termes de couleur et de finition. Nous prenons également en compte les spécificités de votre bâtiment, telles que le type de matériau de façade, afin de sélectionner les produits de peinture adaptés.`,
    subTitle3: ``,
    textData3: `Notre équipe de peintres expérimentés prépare minutieusement la surface en la nettoyant, en réparant les imperfections et en égalisant les surfaces endommagées. Nous utilisons des techniques professionnelles pour assurer une adhérence optimale de la peinture et une finition lisse et uniforme.`,
    subTitle4: ``,
    textData4: `Une fois la préparation terminée, nous procédons à l'application de la peinture en utilisant des produits de haute qualité. Nous pouvons vous conseiller sur les choix de couleurs en fonction de l'architecture de votre bâtiment, de l'environnement et de vos préférences personnelles. Notre objectif est de vous offrir une façade fraîchement peinte qui améliore l'esthétique de votre bâtiment tout en le protégeant des intempéries et des éléments extérieurs.`,
    subTitle5: ``,
    textData5: `Nous offrons un service de peinture de toiture pour donner un nouvel aspect à votre maison et améliorer la protection de votre toit. Notre équipe d'experts qualifiés est spécialisée dans la peinture de toitures, et nous utilisons des produits de haute qualité pour assurer des résultats durables et esthétiquement agréables.`,
    subTitle6: ``,
    textData6: `La peinture de toiture présente de nombreux avantages. Elle peut transformer l'apparence de votre maison en lui donnant une allure fraîche et attrayante. De plus, la peinture forme une couche protectrice qui aide à prolonger la durée de vie de votre toit en le protégeant contre les intempéries, les rayons UV et d'autres facteurs environnementaux.`,
    subTitle7: ``,
    textData7: `Pour vous donner un aperçu de l'impact de notre travail, nous mettons à votre disposition une galerie de photos avant/après. Ces images témoignent de l'état initial de la façade, souvent défraîchie et terne, puis du résultat final après notre intervention professionnelle de peinture. Vous pourrez constater par vous-même la transformation visuelle et la valeur ajoutée que notre service de peinture de façades peut apporter à votre bâtiment.`,
    subTitle8: ``,
    textData8: `Nous sommes fiers de notre expertise en matière de peinture de façades et nous nous engageons à fournir des résultats exceptionnels à nos clients. La galerie de photos avant/après est une preuve concrète de notre dévouement envers la qualité et la satisfaction de nos clients. Contactez-nous dès aujourd'hui pour discuter de vos besoins en matière de peinture de façades et découvrez comment nous pouvons transformer l'apparence de votre bâtiment.`,
  },
  {
    id: 2,
    toitureDataImage: [
      {
        id: 1,
        order: "i01",
        image: img10,
        title: "1",
      },
      {
        id: 2,
        order: "i02",
        image: img10b,
        title: "2",
      },
      {
        id: 3,
        order: "i03",
        image: img7,
        title: "03",
      },
      {
        id: 4,
        order: "i04",
        image: img7b,
        title: "04",
      },
      {
        id: 5,
        order: "i05",
        image: img11,
        title: "05",
      },
      {
        id: 6,
        order: "i06",
        image: img21,
        title: "06",
      },
      {
        id: 7,
        order: "i07",
        image: img21b,
        title: "7",
      },
    ],
  },
];

export const ramonageData = [
  {
    id: 3,
    title: "Ramonage",
    textData: `Le ramonage est un service essentiel pour assurer la sécurité et le bon fonctionnement de votre système de chauffage à combustion, en particulier les cheminées. Nous proposons des prestations complètes de ramonage réalisées par nos ramoneurs expérimentés. Voici quelques détails importants à connaître :`,
    subTitle1: `Objectif du ramonage :`,
    textData1: `Le ramonage consiste à éliminer les dépôts de suie, de créosote et d'autres résidus accumulés dans les conduits de fumée. Ces dépôts peuvent obstruer les conduits, réduire l'efficacité du système de chauffage, augmenter le risque d'incendie et causer des émissions dangereuses.`,
    subTitle2: `Fréquence du ramonage :`,
    textData2: `La fréquence recommandée du ramonage varie en fonction du type de combustible utilisé et de l'utilisation de la cheminée. En général, il est recommandé de faire ramoner une cheminée au moins une fois par an, mais cela peut varier. Les assurances et les réglementations locales peuvent également spécifier des exigences spécifiques.`,
    subTitle3: `Processus de ramonage : `,
    textData3: `Nos ramoneurs qualifiés effectuent le ramonage en utilisant des outils spécialisés tels que des brosses, des hérissons et des aspirateurs spéciaux. Ils inspectent les conduits, éliminent les dépôts de suie et de créosote, vérifient l'étanchéité des joints et s'assurent du bon fonctionnement de la cheminée.`,
    subTitle4: `Certificat de ramonage : `,
    textData4: `À la fin du ramonage, nos ramoneurs fournissent un certificat de ramonage qui atteste que l'opération a été effectuée conformément aux normes de sécurité. Ce certificat peut être requis par votre compagnie d'assurance ou par les autorités locales.`,
    subTitle5: `Autres services : `,
    textData5: `En plus du ramonage, nous pouvons également proposer des services complémentaires tels que l'inspection des conduits, la réparation des cheminées, l'installation de chapeaux de cheminée ou de déflecteurs anti-oiseaux pour prévenir les obstructions.`,
    textData6: `Le ramonage est une étape cruciale pour garantir un environnement sûr et sain chez vous. Faites confiance à notre équipe de ramoneurs expérimentés pour effectuer un ramonage professionnel et préserver la performance de votre système de chauffage. Contactez-nous dès maintenant pour prendre rendez-vous et bénéficier de nos services de ramonage de haute qualité.`,
  },
];
export const couvertureData = [
  {
    id: 1,
    title: "Couverture",
    textData: `Nous sommes des experts en réparation de couverture, et nous nous spécialisons notamment dans les réparations de faîtières. Nous sommes heureux de présenter notre service détaillé, accompagné d'une galerie de photos avant/après pour illustrer les résultats impressionnants que nous obtenons.`,
    subTitle1: ``,
    textData1: `La faîtière est une partie essentielle de la couverture de votre toit, située au sommet de l'intersection des deux versants. Elle assure l'étanchéité et la solidité de cette zone vulnérable. Cependant, au fil du temps, les faîtières peuvent subir des dommages dus aux intempéries, aux variations de température ou à d'autres facteurs.`,
    subTitle2: ``,
    textData2: `Lorsque vous constatez des problèmes tels que des fissures, des cassures ou un affaissement de votre faîtière, il est essentiel de faire appel à des professionnels qualifiés pour effectuer les réparations nécessaires. Notre équipe d'experts se rend sur place pour évaluer l'état de votre faîtière et déterminer la meilleure approche de réparation.`,
    subTitle3: ``,
    textData3: `Nous utilisons des techniques spécialisées pour réparer et renforcer votre faîtière de manière efficace. Cela peut impliquer le remplacement des tuiles ou des matériaux endommagés, le renforcement de la structure sous-jacente ou l'application de produits d'étanchéité professionnels pour prévenir les infiltrations d'eau.`,
    subTitle4: ``,
    textData4: `Pour vous donner un aperçu des résultats remarquables que nous obtenons, nous mettons à votre disposition une galerie de photos avant/après. Ces images témoignent de l'état initial de la faîtière, montrant les dommages et les problèmes, puis du résultat final après notre intervention professionnelle de réparation. Vous pourrez constater par vous-même la transformation visuelle et la qualité du travail que nous réalisons.`,
    subTitle5: ``,
    textData5: `Nous sommes fiers de notre expertise en matière de réparation de couverture, et nous nous engageons à fournir des résultats exceptionnels à nos clients. La galerie de photos avant/après est une preuve tangible de notre dévouement envers la qualité et la satisfaction de nos clients. Faites confiance à notre équipe pour réparer et préserver l'intégrité de votre faîtière, assurant ainsi la durabilité et la protection de votre toiture.`,
    subTitle6: ``,
    textData6: `N'hésitez pas à nous contacter dès aujourd'hui pour discuter de vos besoins en matière de réparation de faîtière et découvrez comment nous pouvons restaurer la solidité et l'esthétique de votre toit.`,
  },
  {
    id: 2,
    toitureDataImage: [
      {
        id: 1,
        order: "i01",
        image: img12,
        title: "1",
      },
      {
        id: 2,
        order: "i02",
        image: img12b,
        title: "2",
      },
      {
        id: 3,
        order: "i03",
        image: img13,
        title: "3",
      },
      {
        id: 4,
        order: "i04",
        image: img14,
        title: "4",
      },
    ],
  },
];
export const diverseData = [
  {
    id: 1,
    title: "Diverse Travaux",
    textData: `Nous sommes une entreprise spécialisée dans les travaux de petite maçonnerie, de nettoyage extérieur et d'autres services connexes. Nous sommes fiers de présenter notre large gamme de services, accompagnée d'une galerie de photos avant/après pour illustrer les résultats exceptionnels que nous obtenons.`,
    subTitle1: `Que vous ayez besoin de réparations de maçonnerie, de nettoyage de surfaces extérieures ou d'autres travaux connexes, notre équipe d'experts qualifiés est là pour répondre à vos besoins. Voici un aperçu de nos services :`,
    textData1: `1. Travaux de petite maçonnerie : Nous effectuons des réparations et des améliorations de maçonnerie, telles que la réparation de murs en briques ou en pierres, le rejointement de façades, la construction de petits murs, la réparation de cheminées, et bien plus encore. Nos artisans maçons veillent à ce que les travaux soient effectués avec précision et durabilité.`,
    subTitle2: ``,
    textData2: `2. Nettoyage extérieur : Nous offrons des services complets de nettoyage extérieur pour redonner de l'éclat à votre propriété. Cela comprend le nettoyage de façades, de terrasses, de trottoirs, de murs extérieurs, de clôtures et de toute autre surface extérieure. Nous utilisons des techniques de nettoyage professionnelles, telles que le nettoyage à haute pression, pour éliminer les saletés, les taches, la moisissure et la mousse.`,
    subTitle3: ``,
    textData3: `3. Réparation des revêtements extérieurs : Si votre revêtement extérieur présente des dommages ou des dégradations, nous sommes là pour vous aider. Que ce soit pour réparer des planches de bardage, des panneaux de revêtement ou d'autres éléments, notre équipe peut effectuer des réparations précises pour restaurer l'intégrité et l'apparence de votre revêtement extérieur.`,
    subTitle4: ``,
    textData4: `4. Autres services : Nous proposons également d'autres services, tels que la pose de revêtements de sol extérieurs, l'installation de bordures de jardin, la réparation de clôtures, le remplacement de gouttières, et bien plus encore. Notre objectif est de vous fournir des solutions complètes pour vos besoins en matière d'aménagement extérieur et de réparation.`,
    subTitle5: ``,
    textData5: `5. Découvrez également notre prestation spécialisée dans la peinture des boiseries. Que vous ayez des portes, des fenêtres, des escaliers en bois ou tout autre élément de menuiserie, notre équipe expérimentée est là pour redonner vie à vos boiseries.
    \nLa peinture des boiseries peut transformer l'apparence de votre intérieur ou de votre extérieur en apportant une nouvelle finition, en rafraîchissant les couleurs existantes ou en ajoutant une touche de modernité à votre espace. Nous travaillons avec des peintures de qualité supérieure adaptées aux boiseries, garantissant ainsi une finition durable et esthétique`,
    subTitle6: ``,
    textData6: `Pour vous donner un aperçu des résultats que nous obtenons, nous mettons à votre disposition une galerie de photos avant/après. Ces images témoignent de l'état initial des surfaces et des structures avant notre intervention, puis du résultat final après nos travaux professionnels. Vous pourrez constater par vous-même la transformation visuelle et la qualité de notre travail.`,
    subTitle7: ``,
    textData7: `Nous nous engageons à fournir des services de haute qualité et à satisfaire nos clients. La galerie de photos avant/après est une preuve concrète de notre dévouement envers la qualité et la satisfaction de nos clients. Contactez-nous dès aujourd'hui pour discuter de vos besoins en travaux de petite maçonnerie, de nettoyage extérieur et d'autres services connexes.`,
  },
  {
    id: 2,
    toitureDataImage: [
      {
        id: 1,
        order: "i01",
        image: img15,
        title: "1",
      },
      {
        id: 2,
        order: "i02",
        image: img15b,
        title: "2",
      },
      {
        id: 3,
        order: "i03",
        image: img16,
        title: "3",
      },
      {
        id: 4,
        order: "i04",
        image: img16b,
        title: "4",
      },
      {
        id: 5,
        order: "i05",
        image: img17,
        title: "5",
      },
      {
        id: 6,
        order: "i06",
        image: img17b,
        title: "6",
      },
      {
        id: 7,
        order: "i07",
        image: img18,
        title: "7",
      },
      {
        id: 8,
        order: "i08",
        image: img18b,
        title: "8",
      },
      {
        id: 9,
        order: "i09",
        image: img19,
        title: "9",
      },
      {
        id: 10,
        order: "i10",
        image: img19b,
        title: "10",
      },
      {
        id: 11,
        order: "i11",
        image: img20,
        title: "11",
      },
      {
        id: 12,
        order: "i12",
        image: img20b,
        title: "12",
      },
      {
        id: 13,
        order: "i13",
        image: img24,
        title: "13",
      },
      {
        id: 14,
        order: "i14",
        image: img22,
        title: "14",
      },
    ],
  },
];
