import React from "react";
import RoutesPath from "../routes/Routes.jsx";
import Header from "../components/Header.jsx";
import Footer from "../components/Footer.jsx";
import SecondNavBar from "../components/SecondNavbar.jsx";

const Home = () => {
  return (
    <div className="container">
      <Header />
      <SecondNavBar />
      <RoutesPath />
      <Footer />
    </div>
  );
};

export default Home;
