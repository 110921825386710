import React from "react";
import { diverseData } from "../data/servicesData.js";

const Diverse = () => {
  return (
    <div className="diverse-container">
      <div className="diverse">
        <div className="diverse-top">
          {diverseData
            .filter((filterId) => filterId.id === 1)
            .map((getData, index) => (
              <div key={index} className="diverse-top-left">
                <h1>{getData.title}</h1>
                <h3 className="one">{getData.textData}</h3>
                <h3 className="one">{getData.subTitle1}</h3>
                <p>{getData.textData1}</p>
                <p>{getData.textData2}</p>
                <p>{getData.textData3}</p>
                <p>{getData.textData4}</p>
                <p className="new-line">{getData.textData5}</p>
                <p>{getData.textData6}</p>
                <p>{getData.textData7}</p>
              </div>
            ))}
        </div>
        <div className="diverse-bottom-left">
          {diverseData
            .filter((filterId) => filterId.id === 2)
            .map((getData) =>
              getData.toitureDataImage.map((getImg) => (
                <figure className={getImg.order} key={getImg.id}>
                  <img src={getImg.image} alt={getImg.title} />
                </figure>
              ))
            )}
        </div>
      </div>
    </div>
  );
};

export default Diverse;
