import React from "react";
import { NavLink } from "react-router-dom";
import { AuthUser } from "../context/Auth.jsx";

const SecondNavbar = () => {
  const { secondNavbar } = AuthUser();

  return (
    <div className="second-navbar-container">
      <nav>
        <ul>
          {secondNavbar.map((data) => {
            return (
              <li key={data.id} className={data.order}>
                <NavLink to={data.link} className={data.order}>
                  {data.title}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default SecondNavbar;
