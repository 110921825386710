const swiperData = [
  {
    id: 1,
    sliderName: "",
    image: "img1",
    title: "Nettoyage de toiture",
    subtitle: "Toiture propre et éclatante grâce à notre nettoyage spécialisé",
    button: "blue",
    classStyle: "",
    description: `Qu'il s'agisse de mousses, d'algues, de saletés ou de débris, notre équipe d'experts sait comment éliminer efficacement
     les taches et les accumulations tenaces. Grâce à nos techniques de nettoyage de haute qualité et à nos produits respectueux 
     de l'environnement, votre toiture retrouvera toute sa beauté et son intégrité d'origine.`,
    link: "/prestations/nettoyage",
  },
  {
    id: 2,
    sliderName: "",
    image: "img2",
    title: "Nettoyage de façade",
    subtitle: "Façade impeccable grâce à notre nettoyage de qualité",
    button: "red",
    classStyle: "",
    description: `Des salissures, de la pollution, des taches tenaces ? Notre équipe d'experts sait comment éliminer efficacement toutes 
    les impuretés qui ternissent l'aspect de vos façades. Grâce à nos méthodes de nettoyage avancées et à nos produits de qualité, 
    nous redonnerons à vos façades leur éclat et leur allure d'origine.`,
    link: "/prestations/nettoyage",
  },
  {
    id: 3,
    sliderName: "",
    image: "img3",
    title: "Peinture de façade",
    subtitle: "Revitalisez votre façade avec notre peinture spécialisée",
    button: "green",
    classStyle: "",
    description: `Que vos façades présentent des signes de décoloration, des fissures ou simplement besoin d'un nouveau look, notre équipe 
    d'experts en peinture sait comment apporter une solution esthétique et durable. Avec notre savoir-faire et notre choix de 
    couleurs de qualité, nous redonnerons vie à vos façades et créerons une apparence fraîche et attrayante.`,
    link: "/prestations/peinture",
  },
  {
    id: 4,
    sliderName: "",
    image: "img4",
    title: "Ramonage",
    subtitle: "Cheminée sûre grâce à notre ramonage professionnel",
    button: "black",
    classStyle: "",
    description: `Avec notre savoir-faire et notre attention aux détails, nous veillons à ce que votre cheminée 
    fonctionne de manière optimale et en toute sécurité. Faites confiance à notre service de ramonage professionnel pour
     maintenir votre cheminée en parfait état de marche.`,
    link: "/prestations/ramonage",
  },
  {
    id: 5,
    sliderName: "",
    image: "img5",
    title: "Couverture",
    subtitle:
      "Réparations de couverture professionnelles pour une toiture solide",
    button: "black",
    classStyle: "",
    description: `Grâce à notre expertise et à l'utilisation de matériaux de qualité supérieure, nous sommes en mesure
     de réaliser des réparations durables et résistantes aux intempéries. Nous nous engageons à vous offrir un service
      professionnel, une communication transparente et des résultats exceptionnels. `,
    link: "/prestations/couverture",
  },
  {
    id: 6,
    sliderName: "",
    image: "img6",
    title: "Diverse Travaux",
    subtitle:
      "Des compétences variées pour tous vos projets de construction et de rénovation",
    button: "black",
    classStyle: "",
    description: `Nous sommes une entreprise polyvalente spécialisée dans une large gamme de travaux. Que vous ayez besoin de rénover une structure existante, de poser des pavés ou de réaliser d'autres travaux de maçonnerie, notre équipe expérimentée est prête à relever le défi. `,
    link: "/prestations/diverse",
  },
];

export default swiperData;
