import React from "react";
import { NavLink } from "react-router-dom";
import logoFree from "../assets/logo-free.webp";

const Logo = () => {
  return (
    <div className="logo">
      <NavLink to="/">
        <img src={logoFree} alt="mainlogo" />
      </NavLink>
    </div>
  );
};

export default Logo;
