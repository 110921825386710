import React from "react";
import { NavLink } from "react-router-dom";
import { AuthUser } from "../context/Auth.jsx";

const ResponsiveNavbar = ({ ...props }) => {
  const { secondNavbar } = AuthUser();

  return (
    <div
      className="responsive-container"
      onClick={() => props.setResponsiveActive(false)}
      onMouseOver={() => {
        props.setResponsiveActive(true);
      }}
    >
      <nav>
        <ul>
          {secondNavbar.map((data) => {
            return (
              <li key={data.id}>
                <NavLink to={data.link}>{data.title}</NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default ResponsiveNavbar;
