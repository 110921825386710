import React from "react";
import Slider from "../components/Swiper.jsx";

const Main = () => {
  return (
    <div className="home-container">
      <Slider />
    </div>
  );
};

export default Main;
