import React from "react";
import { NavLink } from "react-router-dom";
import { servicesIntro } from "../data/mainData.js";
import { secondNavbar } from "../data/navData.js";

const Services = () => {
  const { services, servicesTitle } = servicesIntro;

  return (
    <div className="services-container">
      <div className="services">
        <div className="services-content">
          <div className="services-top">
            <h1>{servicesTitle}</h1>
            <h3>{services}</h3>
          </div>
          {secondNavbar.map((data) => {
            return (
              <NavLink key={data.id} to={data.link} className={data.order}>
                <figure className={data.dataClass}>
                  <h1>{data.title}</h1>
                  <figcaption>
                    <button>Découvrir</button>
                  </figcaption>
                </figure>
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Services;
