import React from "react";

const ContactForm = ({ ...props }) => {
  return (
    <div className="contact-form">
      <form
        action="#"
        onSubmit={(e) => {
          props.handleSubmit(e);
          props.validateFields(e);
        }}
        id="form-reset"
      >
        {props.fieldsConfig &&
          props.fieldsConfig.map((field) => {
            return (
              <div key={field.id}>
                {field.type !== "textarea" ? (
                  <div>
                    <label>{/*field.label*/}</label>
                    <input
                      type={field.type}
                      className={field.classType}
                      placeholder={field.placeholder}
                      name={field.fieldName}
                      value={field.name}
                      data-check={field.isRequired}
                      onChange={(e) => {
                        props.validateFields(e);
                        props.handleForm(e);
                      }}
                    />
                    <div className="error-message">
                      {field.fieldName === "email" && props.ckEmail}
                      {field.fieldName === "subject" && props.ckSubject}
                    </div>
                  </div>
                ) : (
                  <div className="text">
                    <label>{/*field.label*/}</label>
                    <textarea
                      className={field.classType}
                      placeholder={field.placeholder}
                      name={field.fieldName}
                      data-check={field.isRequired}
                      onChange={(e) => {
                        props.validateFields(e);
                        props.handleForm(e);
                      }}
                      value={field.name}
                    />
                    <div className={props.ckMessage ? "error-message" : "none"}>
                      {props.ckMessage && props.ckMessage}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        <div className={props.emailSent ? "message-sent" : "none"}>
          {props.emailSent && props.emailSent}
        </div>
        <button onClick={() => props.setEmailSent(false)}>ENVOYER</button>
      </form>
    </div>
  );
};

export default ContactForm;
