import React from "react";
import { ramonageData } from "../data/servicesData.js";

const Ramonage = () => {
  return (
    <div className="ramonage-container">
      <div className="ramonage">
        <div className="ramonage-top">
          {ramonageData.map((getData) => {
            return (
              <div key={getData.id} className="ramonage-top-left">
                <h1>{getData.title}</h1>
                <h3 className="one">{getData.textData}</h3>
                <h3 className="two">{getData.subTitle1}</h3>
                <p>{getData.textData1}</p>
                <h3 className="two">{getData.subTitle2}</h3>
                <p>{getData.textData2}</p>
                <h3 className="two">{getData.subTitle3}</h3>
                <p>{getData.textData3}</p>
                <h3 className="two">{getData.subTitle4}</h3>
                <p>{getData.textData4}</p>
                <h3 className="two">{getData.subTitle5}</h3>
                <p>{getData.textData5}</p>
                <p className="last">{getData.textData6}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Ramonage;
